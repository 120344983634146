import {Breadcrumbs as MUIBreadcrumbs, Paper, Typography, useMediaQuery} from '@mui/material';
import {Theme} from '@mui/material/styles';
import {TextLink} from 'core/components/text-link';
import Head from 'next/head';
import {useRouter} from 'next/router';
import React, {FC} from 'react';
import * as settings from 'settings';
import {makeStyles} from 'tss-react/mui';
import {UrlObject} from 'url';

const useStyles = makeStyles()((theme: Theme) => ({
    paper: {
        padding: theme.spacing(1, 2),
    }
}));

interface BreadcrumbsProps {
    path: Array<{
        href?: string | UrlObject,
        as?: string,
        label: string,
    }>;
}

export const Breadcrumbs: FC<React.PropsWithChildren<BreadcrumbsProps>> = ({path}) => {
    const print = useMediaQuery('print');
    const {classes} = useStyles();
    const router = useRouter();

    if (print) return null;

    return (
        <Paper elevation={0} className={classes.paper}>
            <Head>
                <title>
                    {path.map(({label}) => label).reverse().concat([settings.SITE_NAME]).join(' / ')}
                </title>
            </Head>
            <MUIBreadcrumbs>
                <TextLink href='/' >Inicio</TextLink>
                {path.map(({href, as, label}) => {
                    if (href) return <TextLink key={label} href={href} as={as}>{label}</TextLink>;
                    return <Typography key={label} color='textPrimary'>{label}</Typography>;
                })}
            </MUIBreadcrumbs>
        </Paper>
    );
};
